<template>
    <div class="full-size">
        <div>
            <b-g-decorate></b-g-decorate>
            <el-image class="login-header-image" :src="require('@images/logo-big.png')"/>
        </div>
        <div class="login-form">
            <el-form ref="el-form" :model="formData" :rules="rules" label-position="top" :status-icon="true"
                     :inline-message="true" :show-message="false">
                <el-card class="login-card">
                    <div class="card-label">
                        <svg-icon icon="icon_miantiao" width="58" height="32"/>
                        <span class="label-title">登陆</span>
                    </div>
                    <el-form-item prop="mobile" label="手机号">
                        <el-input v-model="formData.mobile" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" label="密码">
                        <el-input v-model="formData.password" placeholder="请输入密码" type="password"></el-input>
                    </el-form-item>
                    <div class="forget-pwd">
                        <span @click="toForgetPage">忘记密码</span>
                    </div>
                    <el-form-item prop="isCheckProtocol" class="layout-protocol">
                        <el-checkbox v-model="formData.isCheckProtocol" class="protocol-check" checked></el-checkbox>
                        登陆即代表同意<span class="protocol-text" @click="toUserProtocolPage">《用户协议》</span>
                    </el-form-item>
                </el-card>

                <el-button class="submit-button" @click="toLogin">登陆</el-button>
                <el-button class="cancel-button" @click="toRegister">注册</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
    import BGDecorate from "@/components/BGDecorate";

    export default {
        name: "LoginPage",
        components: {BGDecorate},
        data() {
            return {
                //表单数据
                formData: {
                    // mobile: "18374843797",
                    // password: "a111111",
                    mobile: "",
                    password: "",
                    isCheckProtocol: false,
                },
                rules: {
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {len: 11, message: '请检查手机号长度', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            pattern: /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,16}$/,
                            message: '密码长度需为6-19位字母数字组合',
                            trigger: 'blur'
                        }
                    ],
                    isCheckProtocol: [
                        {
                            type: 'boolean',
                            required: true, message: '请阅读并同意《注册协议》', validator: function (rule, value, callback) {
                                if (!value) {
                                    callback(new Error('请阅读并同意《注册协议》'));
                                }
                                callback();
                            },
                        },
                    ]
                }
            }
        },
        created() {
            // console.log("@当前用户信息：", this.user);
            if (this.user != null) {
                //已经登陆
                this.$router.push({path: "/home"})
            }
        },
        methods: {
            toLogin() {
                let _this = this;
                this.$refs["el-form"].validate((valid, error) => {
                    if (valid) {
                        _this.$api.login({
                            phoneNo: _this.formData.mobile,
                            password: _this.formData.password,
                        }).then(res => {
                            _this.setUser(res.userInfo)
                            _this.setToken(res.authToken)
                            _this.$notify.success("登陆成功")
                            _this.$router.push({path: '/home'});
                        }).catch(err => {
                            _this.$notify.error(err.message)
                        })
                    } else {
                        this._showErrorValidMsg(error)
                    }
                });
            },
            toRegister() {
                this.$router.push({path: '/register'});
            },
            toForgetPage() {
                this.$router.push({path: '/forgetPwd'});
            },
            toUserProtocolPage() {
                this.$router.push({
                    path: '/protocol', query: {
                        type: 'user'
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">

    /*头部*/
    .login-header-image {
        width: 40%;
        margin-top: 10%;
    }

    /*表单div*/
    .login-form {
        margin-top: 10%;

        .layout-protocol {
            color: @font-desc;
            font-size: 10px;

            /deep/ .el-checkbox__inner {
                border-color: @color-main;
                background-color: @color-main;
            }

            .protocol-text {
                color: @color-main;
            }
        }
    }

    /*表单*/
    .login-card {
        margin: 0px 34px;
        background: #FFFFFF;
        box-shadow: 3px 3px 50px #D6DBDE;
        border-radius: 24px;
        text-align: left;

        /*标题*/

        .card-label {
            position: relative;

            .label-title {
                position: absolute;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                left: 10px;
                top: 5px;
            }

            .label-icon {
                position: relative;
                left: 0px;
            }
        }

        .forget-pwd {
            text-align: right;
            color: @color-main;
            font-size: 12px;
        }

        /*验证码*/

        .btn-send-code {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 2px;
            /* identical to box height */
            text-decoration-line: underline;
            color: #FF9534;
        }

        .input_ver_code {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            /deep/ .el-input {
                width: auto;
                margin-right: 5px;
            }

            /deep/ .el-input__inner {
                width: 30px;
                height: 30px;
            }
        }
    }


</style>
